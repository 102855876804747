<template>
  <div class="AddMission">
    <CModal
      :show.sync="isMissionCreated"
      :no-close-on-backdrop="true"
      title="Mission créée"
      size="lg"
      color="dark"
    >
    <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
      Votre mission a bien été créée 👍
    </p>
    <p>
      Vous pouvez maintenant accéder au tableau de bord de la mission pour y affecter des collaborateurs.
    </p>
    <template #footer>
      <CButton @click="$router.push('/mission/' + createdMissionId)" color="primary">Accéder au tableau de bord de votre mission</CButton>
      <CButton @click="$router.push('/manager-all-missions')" color="dark">Retour à la liste des missions</CButton>
    </template>
    </CModal>

    <loading
      :active.sync="isConflitPreventingLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#B40404">
      <div class="after-icon mt-3" slot="after">
        <strong>Prévention des confilts d'intérêts et duplicata</strong>
      </div>
    </loading>

    <CRow>
      <CCol>
        <h1> Créer une nouvelle mission </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CRow>
          <CCol sm="6">
            <span> Client associé : </span>
            <strong v-if="newMissionClientObject.id != ''"> {{newMissionClientObject.name}} </strong>
            <strong v-else> Aucun </strong>

            <vue-bootstrap-typeahead
              class="mt-2"
              v-model="newMissionClientName"
              :data="clients"
              :serializer="item => item.name"
              @hit="newMissionClientObject = $event"
               placeholder="Chercher votre client par nom"
            />

            <small class="text-muted">
              Vous ne trouvez pas votre client ?
            </small>
            <router-link :to="{ name: 'Ajouter un nouveau client'}">
              <small class="text-muted">Ajouter un nouveau client en cliquant ici</small>
            </router-link>

          </CCol>
          <CCol sm="6">
            <div role="group" class="form-group">
              <span> Référent de la mission : </span>
              <strong v-if="newMissionReferentObject.id != ''"> {{newMissionReferentObject.full_name}} </strong>
              <strong v-else> Aucun </strong>

              <vue-bootstrap-typeahead
                class="mt-2"
                v-model="newMissionReferentName"
                :data="referents"
                :serializer="item => item.full_name"
                @hit="newMissionReferentObject = $event"
                 placeholder="Chercher votre collaborateur par nom ou prénom"
              />
            </div>
          </CCol>
        </CRow>


        <CRow class="mt-3" v-if="!$v.newMissionClientObject.$invalid && clientActiveMissions.length > 0">
          <CCol>
            <strong>  <span class="text-danger"> <CIcon name="cil-warning"/> Prévention des conflits d'intérêts et des duplicata</span> : Missions en cours associées à ce client </strong>
            <CDataTable
              :items="clientActiveMissions"
              :fields="clientActiveMissionsRenderFields"
              :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
            >

            <template #types="{item}">
              <td>
                <em v-for="type in item.types" v-bind:key="type.id">{{type.name}} </em>
              </td>
            </template>

            <template #description="{item}">
              <td>
                <small> {{item.description}}</small>
              </td>
            </template>
            </CDataTable>
          </CCol>
        </CRow>

        <CRow class="mt-3" v-if="!$v.newMissionClientObject.$invalid && clientActiveMissions.length == 0">
          <CCol>
            <strong> Prévention des conflits d'intérêts et des duplicata : Aucune mission en cours associée à ce client </strong>
          </CCol>
        </CRow>
        <hr>




        <CRow class="mt-3">
          <CCol sm="5">
            Souhaitez-vous également affecter un contact de votre base à votre mission ?
          </CCol>
          <CCol sm="7">
            <CInputRadioGroup
              :checked.sync="wantsToAddContact"
              :custom="true"
              :inline="true"
              :options='booleanArrayForm'
            />
          </CCol>
        </CRow>

        <div class="mt-2 mb-2" v-if="wantsToAddContact == 'true'">
          <span> Votre contact choisi : </span>
          <strong v-if="newMissionContactObject.id != ''"> {{newMissionContactObject.full_name}} </strong>
          <strong v-else> Aucun </strong>

          <vue-bootstrap-typeahead
            class="mt-2"
            v-model="newMissionContactName"
            :data="contacts"
            :serializer="item => item.full_name_email"
            @hit="newMissionContactObject = $event"
             placeholder="Chercher votre contact par nom ou prénom"
          />
        </div>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <strong>Caractéristiques de la mission</strong>
      </CCardHeader>
      <CCardBody>


        <CInput
          label="Nom de la mission"
          v-model="newMissionName" type="text"
          maxlength="150"
          placeholder="Nom"
          @input="$v.newMissionName.$touch()"
          :isValid="$v.newMissionName.$dirty ? !$v.newMissionName.$error : null"
          invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
        >
        </CInput>

        <div role="group" class="form-group">
          <label for="add-mission-types-input">Type(s) de la mission</label>
          <vue-multiselect-input
            id="add-mission-types-input"
            name="add-mission-types-input"
            v-model="newMissionTypes"
            :options="missionsSelectOptions"
            :multiple="true"
            :group-select="true"
            placeholder="Choisissez un ou plusieurs types de mission"
            selectLabel="Ajouter"
            deselectLabel="Supprimer"
            selectedLabel="Sélectionné"
            track-by="value"
            label="label">
            <span slot="noResult">Aucun élément trouvé, veuillez réessayer</span>
            <span slot="noOptions">Aucun type de mission</span>
          </vue-multiselect-input>
        </div>

        <CRow class="mb-2">
          <CCol>
            Mission facturée
          </CCol>
        </CRow>
        <CRow class="mb-2">
          <CCol>
            <CInputRadioGroup
              :checked.sync="newMissionIsBilled"
              :custom="true"
              :inline="true"
              :options='booleanArrayForm'
            />
          </CCol>
        </CRow>

        <CRow v-if="newMissionIsBilled == 'true'" class="mb-2">
          <CCol>
            <CRow>
              <CCol tag="label">
                Type de contrat
              </CCol>
            </CRow>
            <CRow>
              <CCol class="form-inline">
                <CInputCheckbox
                  label="Honoraires"
                  :custom="true"
                  name="new-mission-input-honoraires"
                  :checked.sync="newMissionIsHonoraires"
                  :inline="true"
                />
                <CInputCheckbox
                  label="Honoraires de résultats"
                  :custom="true"
                  name="new-mission-input-honoraires-resultats"
                  :checked.sync="newMissionIsHonorairesResultats"
                  :inline="true"
                />
                <CInputCheckbox
                  label="Régie plafonnée"
                  :custom="true"
                  name="new-mission-input-regie"
                  :checked.sync="newMissionIsRegiePlafonnee"
                  :inline="true"
                />
                <CInputCheckbox
                  label="Forfait"
                  :custom="true"
                  name="new-mission-input-forfait"
                  :checked.sync="newMissionIsForfait"
                  :inline="true"
                />
                <CInputCheckbox
                  label="AJ"
                  :custom="true"
                  name="new-mission-input-aj"
                  :checked.sync="newMissionIsAJ"
                  :inline="true"
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol  v-if="newMissionIsHonoraires || newMissionIsRegiePlafonnee || newMissionIsForfait" sm="6">
            <CInput
              label="Montant de la mission (facultatif)"
              v-model="newMissionMontantHT" type="number"
              maxlength="50"
              placeholder="Montant en € HT (facultatif)"
              @input="$v.newMissionMontantHT.$touch()"
              :isValid="$v.newMissionMontantHT.$dirty ? !$v.newMissionMontantHT.$error : null"
              min="0" max="100000000" step=".01" @blur="newMissionMontantHTCorrection"
            >
            </CInput>
          </CCol>

          <CCol v-if="newMissionIsHonorairesResultats" sm="6">
            <CInput
              prepend="%"
              label="Pourcentage du résultat (facultatif)"
              v-model="newMissionResultatsPercentage" type="number"
              placeholder="Renseignez le pourcentage de résultat"
              @input="$v.newMissionResultatsPercentage.$touch()"
              :isValid="$v.newMissionResultatsPercentage.$dirty ? !$v.newMissionResultatsPercentage.$error : null"
              min="0" max="100" step="1"
            >
            </CInput>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="6">

            <div v-if="$browserDetect.isSafari">
              <b-form-group id="create-mission-date_debut-group" label="Date de début de la mission" label-for="create-mission-date_debut-input">
                <b-form-datepicker
                  id="create-mission-date_debut-input"
                  name="create-mission-date_debut-input"
                  locale="fr"
                  placeholder="Choisissez une date"
                  v-model="newMissionStartingDate" class="mb-2"
                  @input="$v.newMissionStartingDate.$touch()"
                  :state="$v.newMissionStartingDate.$dirty ? !$v.newMissionStartingDate.$error : null">
                </b-form-datepicker>
                <small class="text-danger" v-if="!$v.newMissionStartingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
              </b-form-group>
            </div>
            <div v-else>
              <CInput
                label="Date de début de la mission"
                v-model="newMissionStartingDate" type="date"
                placeholder="AAAA-MM-DD"
                @input="$v.newMissionStartingDate.$touch()"
                :isValid="$v.newMissionStartingDate.$dirty ? !$v.newMissionStartingDate.$error : null"
              >
              <template #description>
                <div v-if="$browserDetect.isIE" class="text-info">
                  <small>Vous utilisez Internet Explorer, veuillez entrer votre
                  date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                </div>
                <small class="text-danger" v-if="!$v.newMissionStartingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
              </template>
              </CInput>
            </div>

          </CCol>
          <CCol sm="6">

            <div v-if="$browserDetect.isSafari">
              <b-form-group id="create-mission-date_fin-group" label="Date de fin de la mission (facultatif)" label-for="create-mission-date_fin-input">
                <b-form-datepicker
                  id="create-mission-date_fin-input"
                  name="create-mission-date_fin-input"
                  locale="fr"
                  placeholder="Choisissez une date"
                  v-model="newMissionEndingDate" class="mb-2"
                  @input="$v.newMissionEndingDate.$touch()"
                  :state="$v.newMissionEndingDate.$dirty ? !$v.newMissionEndingDate.$error : null">
                </b-form-datepicker>
                <small class="text-danger" v-if="!$v.newMissionEndingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
              </b-form-group>
            </div>
            <div v-else>
              <CInput
                label="Date de fin de la mission (facultatif)"
                v-model="newMissionEndingDate" type="date"
                placeholder="AAAA-MM-DD"
                @input="$v.newMissionEndingDate.$touch()"
                :isValid="$v.newMissionEndingDate.$dirty ? !$v.newMissionEndingDate.$error : null"
                invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
              >
              <template #description>
                <div v-if="$browserDetect.isIE" class="text-info">
                  <small>Vous utilisez Internet Explorer, veuillez entrer votre
                  date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                </div>
                <small class="text-danger" v-if="!$v.newMissionEndingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
              </template>
              </CInput>
            </div>
          </CCol>
        </CRow>

        <CInput
          label="Numéro RG (facultatif)"
          v-model="newMissionRGNumber" type="text"
          maxlength="49"
          placeholder="Référence"
          @input="$v.newMissionRGNumber.$touch()"
          :isValid="$v.newMissionRGNumber.$dirty ? !$v.newMissionRGNumber.$error : null"
          invalid-feedback="La référence doit comporter entre 2 et 50 caractères"
        >
        </CInput>

        <CTextarea
          label="Décrivez brièvement le contenu de la mission"
          v-model="newMissionDescription" type="text"
          rows="3"
          maxlength="999"
          placeholder="Description"
          @input="$v.newMissionDescription.$touch()"
          :isValid="$v.newMissionDescription.$dirty ? !$v.newMissionDescription.$error : null"
        >
        </CTextarea>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <strong>Partie adverse</strong>
      </CCardHeader>
      <CCardBody>

        <CRow class="mb-2">
          <CCol sm="5">
            Souhaitez-vous renseigner les informations de la partie adverse ?
          </CCol>
          <CCol sm="7">
            <CInputRadioGroup
              :checked.sync="wantsToAddContreInformation"
              :custom="true"
              :inline="true"
              :options='booleanArrayForm'
            />
          </CCol>
        </CRow>

        <div v-if="wantsToAddContreInformation == 'true'">

          <CRow class="mb-2">
            <CCol sm="2">
              La partie adverse est
            </CCol>
            <CCol sm="10">
              <CInputRadioGroup
                :checked.sync="newMissionContreClientIsFirm"
                :custom="true"
                :inline="true"
                :options='clientIsFirmArrayForm'
              />
            </CCol>
          </CRow>
          <div v-if="newMissionContreClientIsFirm =='true'">
            <CRow >
              <CCol sm="6">
                <CInput
                  label="Siren de la partie adverse"
                  autocomplete="eclerk-no-autocomplete-input"
                  v-model="newMissionContreClientSiren" type="text"
                  v-mask="'NNN NNN NNN'"
                  maxlength="11"
                  placeholder="XXX XXX XXX"
                  @input="$v.newMissionContreClientSiren.$touch()"
                  :isValid="$v.newMissionContreClientSiren.$dirty ? !$v.newMissionContreClientSiren.$error : null"
                  invalid-feedback="Le numéro de SIREN doit être composé de 9 chiffres"
                >
                </CInput>
              </CCol>
              <CCol sm="6">
                <CInput
                label="Nom de la partie adverse"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="newMissionContreClientName" type="text"
                maxlength="150"
                placeholder="Nom de la partie adverse"
                @input="$v.newMissionContreClientName.$touch()"
                :isValid="$v.newMissionContreClientName.$dirty ? !$v.newMissionContreClientName.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
              </CCol>
            </CRow>

          </div>

          <div v-if="newMissionContreClientIsFirm =='false'">
            <CRow >
              <CCol sm="6">
                <CInput
                  label="Prénom"
                  autocomplete="eclerk-no-autocomplete-input"
                  v-model="newMissionContreClientFirstName" type="text"
                  maxlength="150"
                  placeholder="Prénom"
                  @input="$v.newMissionContreClientFirstName.$touch()"
                  :isValid="$v.newMissionContreClientFirstName.$dirty ? !$v.newMissionContreClientFirstName.$error : null"
                  invalid-feedback="Le prénom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Nom"
                  autocomplete="eclerk-no-autocomplete-input"
                  v-model="newMissionContreClientLastName" type="text"
                  maxlength="150"
                  placeholder="Nom"
                  @input="$v.newMissionContreClientLastName.$touch()"
                  :isValid="$v.newMissionContreClientLastName.$dirty ? !$v.newMissionContreClientLastName.$error : null"
                  invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
              </CCol>
            </CRow>
          </div>

          <CRow>
            <CCol sm="6">
              <CInput
                label="Nom de l'avocat adverse"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="newMissionContreAvocatName" type="text"
                maxlength="150"
                placeholder="Nom"
                @input="$v.newMissionContreAvocatName.$touch()"
                :isValid="$v.newMissionContreAvocatName.$dirty ? !$v.newMissionContreAvocatName.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CInput
                label="Email de l'avocat adverse"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="newMissionContreAvocatEmail" type="email"
                maxlength="150"
                placeholder="Nom"
                @input="$v.newMissionContreAvocatEmail.$touch()"
                :isValid="$v.newMissionContreAvocatEmail.$dirty ? !$v.newMissionContreAvocatEmail.$error : null"
                invalid-feedback="Veuillez rentrer un email valide"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow class="mt-3">
            <CCol sm="12">
              <div role="group" class="form-group">
                <label for="addMissionContreAvocattVuePhoneNumber" class>
                  Numéro de téléphone (facultatif)
                </label>
                <vue-phone-number-input
                  id="addMissionContreAvocattVuePhoneNumber"
                  name="addMissionContreAvocattVuePhoneNumber"
                  v-model="newMissionContreAvocatPhoneNumber"
                  :default-country-code='newMissionContreAvocatPhoneNumberResult.countryCode'
                  :preferred-countries="['FR', 'BE', 'DE']"
                  valid-color='#28a745'
                  error-color='#dc3545'
                  :translations="{
                    countrySelectorLabel: 'Code pays',
                    countrySelectorError: 'Choisir un pays',
                    phoneNumberLabel: 'Numéro de téléphone',
                    example: 'Exemple :'
                  }"
                  @update='newMissionContreAvocatPhoneNumberResult = $event'
                  :required='false'
                />
                <small class="text-danger" v-if="newMissionContreAvocatPhoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
              </div>
            </CCol>
          </CRow>
        </div>

      </CCardBody>
    </CCard>

    <CRow class="mb-4">
      <CCol class="text-center">
        <CButton
          @click="addMission" :color="addMissionButtonStyle"
          shape="pill" block class="px-4" size="lg"
          :disabled="isInvalidAddMission || addMissionInProcess">
          <CSpinner size="sm" label="create mission spinner" v-if="addMissionInProcess"></CSpinner>
          {{ addMissionButtonText }}
        </CButton>
      </CCol>
    </CRow>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { isStringIntegerOrBlank } from '@/validators/validators'
import { required, minLength, maxLength, minValue, maxValue, integer, email} from 'vuelidate/lib/validators'
import { APIManagerConnected } from '@/api/APIManagerConnected'
import { APIUserConnected } from '@/api/APIUserConnected'
import { isDate, isDateOrBlank } from '@/validators/validators'

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiManagerConnected = new APIManagerConnected()
const apiUserConnected = new APIUserConnected()


export default {
  name: 'AddMission',
  components: {
    VueBootstrapTypeahead,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    const today = this.$dayjs().format('YYYY-MM-DD')
    return {
      // --------- MISSION TYPE -----------
      isMissionTypesLoading: false,
      missionTypes: [],
      missionsSelectOptions: [],

      // --------- ADD MISSION ------------
      isMissionCreated: false,
      newMissionName: '',
      newMissionTypes: [],
      newMissionIsBilled: 'true',
      newMissionIsHonoraires: false,
      newMissionIsHonorairesResultats: false,
      newMissionIsRegiePlafonnee: false ,
      newMissionIsForfait: false,
      newMissionIsAJ: false,
      newMissionMontantHT: '',
      newMissionResultatsPercentage: '',
      newMissionStartingDate: today,
      newMissionEndingDate: '',
      newMissionRGNumber: '',
      newMissionDescription: '',

      wantsToAddContreInformation: 'false',
      newMissionContreClientIsFirm: 'true',
      clientIsFirmArrayForm: [
        { label: 'Une personne morale', value: 'true' },
        { label: "Une personne physique", value: 'false' },
      ],

      newMissionContreClientSiren: '',
      newMissionContreClientName: '',
      newMissionContreClientFirstName: '',
      newMissionContreClientLastName: '',
      newMissionContreAvocatName: '',
      newMissionContreAvocatEmail: '',
      newMissionContreAvocatPhoneNumber: null,
      newMissionContreAvocatPhoneNumberResult: {
        countryCode: "FR",
      },


      clients: [],
      newMissionClientName: '',
      newMissionClientObject: {
        id: '',
        name: ''
      },

      referents: [],
      newMissionReferentName: '',
      newMissionReferentObject: {
        id: '',
        full_name: ''
      },

      wantsToAddContact: 'false',
      contacts: [],
      newMissionContactName: '',
      newMissionContactObject: {
        id: '',
        full_name: ''
      },

      booleanArrayForm: [
        { label: 'Oui', value: 'true' },
        { label: 'Non', value: 'false' },
      ],

      addMissionButtonText: 'Créer une nouvelle mission',
      addMissionButtonStyle: 'primary',
      addMissionInProcess: false,

      createdMissionId: '',

      // PREVENTION CONFLITS D'INTERETS
      clientActiveMissions : [],
      isSearchingClientActiveMissions: false,
      duplicateClientActiveMissionsHasBeenSearched: false,
      clientActiveMissionsRenderFields: [
        { key: "name", label: "Intitulé mission", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "types", label: "Type de mission", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "referent_full_name", label: "Référent mission", tdClass: 'ui-helper-center', _style: "min-width: 150px;"},
      ],
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    authHeaders () {
      return apiUserConnected.getAuthHeaders(this.token)
    },

    isConflitPreventingLoading () {
      if (this.isSearchingClientActiveMissions) {
        return true
      }
      return false
    },
    isInvalidAddMission () {
      if (this.$v.newMissionContactObject.$invalid || this.$v.newMissionClientObject.$invalid ||
      this.$v.newMissionReferentObject.$invalid || this.$v.newMissionName.$invalid ||
      this.$v.newMissionTypes.$invalid || this.$v.newMissionIsBilled.$invalid || this.$v.newMissionMontantHT.$invalid || this.$v.newMissionResultatsPercentage.$invalid ||
      this.$v.newMissionStartingDate.$invalid || this.$v.newMissionEndingDate.$invalid || this.$v.newMissionRGNumber.$invalid || this.$v.newMissionDescription.$invalid || this.$v.newMissionContreClientSiren.$invalid ||
      this.$v.newMissionContreClientName.$invalid || this.$v.newMissionContreClientFirstName.$invalid || this.$v.newMissionContreClientLastName.$invalid || this.$v.newMissionContreAvocatName.$invalid ||
      this.$v.newMissionContreAvocatEmail.$invalid || this.$v.newMissionContreAvocatPhoneNumberResult.$invalid) {
        return true
      }
      return false
    },

    finalNewMissionContreClientSiren () {
      return this.newMissionContreClientSiren.replace(/\s/g,'');
    },

    newMissionTypesIdArray () {
      var final_array = []
      for (var i = 0; i < this.newMissionTypes.length; i++) {
        final_array.push(
          this.newMissionTypes[i].value,
        )
      }
      return final_array
    }
  },
  created: function() {
    this.getAllMissionTypes()
  },
  validations: {
    newMissionContactObject: {
      id: {
        integer
      }
    },
    newMissionClientObject: {
      id: {
        required,
        integer
      }
    },
    newMissionReferentObject: {
      id: {
        required,
        integer
      }
    },
    newMissionName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    newMissionTypes: {
      required,
      isNotEmptyArray (value) {
        if (value.length > 0) {
          return true
        }
        else {
          return false
        }
      }
    },
    newMissionIsBilled: {
      required
    },
    newMissionIsHonoraires: {
      required
    },
    newMissionIsHonorairesResultats: {
      required
    },
    newMissionIsRegiePlafonnee: {
      required
    },
    newMissionIsForfait: {
      required
    },
    newMissionIsAJ: {
      required
    },
    newMissionMontantHT: {
      minValue: minValue(1),
      maxValue: maxValue(100000000)
    },
    newMissionResultatsPercentage: {
      integer,
      minValue: minValue(0),
      maxValue: maxValue(100)
    },
    newMissionStartingDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    newMissionEndingDate: {
      validDate(value) {
        return isDateOrBlank(value)
      }
    },
    newMissionRGNumber: {
      minLength: minLength(2),
      maxLength: maxLength(49)
    },
    newMissionDescription: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(1000)
    },

    // CONTRE INFORMATIONS
    newMissionContreClientSiren: {
      minLength: minLength(11),
      maxLength: maxLength(11),
      validSiren(value) {
        return isStringIntegerOrBlank(value)
      },
    },
    newMissionContreClientName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
    },
    newMissionContreClientFirstName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
    },
    newMissionContreClientLastName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
    },

    newMissionContreAvocatName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
    },
    newMissionContreAvocatEmail: {
      email,
      minLength: minLength(2),
      maxLength: maxLength(249),
    },
    newMissionContreAvocatPhoneNumberResult: {
      countryCode: {
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          if (this.newMissionContreAvocatPhoneNumber == null) {
            return true
          }
          else {
            return value
          }
        }
      }
    },
  },

  watch: {
    missionTypes: function (missionTypesArray) {
      if (missionTypesArray.length == 0) {
        this.missionsSelectOptions = []
      }
      else {
        var final_array = []
        for (var i = 0; i < missionTypesArray.length; i++) {
          final_array.push(
            {
              'label': missionTypesArray[i].name,
              'value': missionTypesArray[i].id,
            }
          )
        }
        this.missionsSelectOptions = final_array
      }
    },

    // TODO When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    newMissionClientName(newQuery) {
      if (newQuery == '') {
        this.clients = []
        this.newMissionClientObject = {
          id: '',
          name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchClientByName(this.token, newQuery)
        .then((res) => {
          this.clients = res.data
        })
      }
    },

    // TODO When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    newMissionReferentName(newQuery) {
      if (newQuery == '') {
        this.referents = []
        this.newMissionReferentObject = {
          id: '',
          full_name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchCollaborateursByFirstNameOrLastName(this.token, newQuery)
        .then((res) => {
          this.referents = res.data
        })
      }
    },

    // TODO When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    newMissionContactName(newQuery) {
      if (newQuery == '') {
        this.contacts = []
        this.newMissionContactObject = {
          id: '',
          full_name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchContactByFirstOrLastName(this.token, newQuery)
        .then((res) => {
          this.contacts = res.data
        })
      }
    },

    newMissionClientObject () {
      this.searchClientActiveMissions()
    }
  },

  methods: {

    // ----------- GLOBALS -----------------
    newMissionMontantHTCorrection () {
      var floatNewMissionHT = parseFloat(this.newMissionMontantHT)
      if (isNaN(floatNewMissionHT)) {
        this.newMissionMontantHT = ''
      }
      else {
        this.newMissionMontantHT = floatNewMissionHT.toFixed(2)
      }
    },
    /// ---------- Getters ------------------

    getAllMissionTypes () {
      this.isMissionTypesLoading = true
      apiUserConnected.getAllMissionTypes(this.token)
      .then((result) => {
        this.missionTypes = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionTypesLoading = false
      })
    },


    // ------------- ADD MISSION ----------
    addMission () {

      if (this.wantsToAddContreInformation == 'false') {
        this.newMissionContreClientIsFirm = 'true'
        this.newMissionContreClientSiren = ''
        this.newMissionContreClientName = ''
        this.newMissionContreClientFirstName = ''
        this.newMissionContreClientLastName = ''
        this.newMissionContreAvocatName = ''
        this.newMissionContreAvocatEmail = ''
        this.newMissionContreAvocatPhoneNumber = null,
        this.newMissionContreAvocatPhoneNumberResult = {
          countryCode: "FR",
        }
      }

      this.addMissionInProcess = true
      this.addMissionButtonText = "Création en cours"
      this.addMissionButtonStyle = 'secondary'

      apiManagerConnected.addMission(this.token,
        this.newMissionContactObject.id, this.newMissionClientObject.id, this.newMissionReferentObject.id, this.newMissionName,
        this.newMissionTypesIdArray, this.newMissionIsBilled, this.newMissionIsHonoraires, this.newMissionIsHonorairesResultats, this.newMissionIsRegiePlafonnee,
        this.newMissionIsForfait, this.newMissionIsAJ, this.newMissionMontantHT, this.newMissionResultatsPercentage, this.newMissionStartingDate,
        this.newMissionEndingDate, this.newMissionRGNumber, this.newMissionDescription, this.newMissionContreClientIsFirm, this.finalNewMissionContreClientSiren, this.newMissionContreClientName,
        this.newMissionContreClientFirstName, this.newMissionContreClientLastName, this.newMissionContreAvocatName, this.newMissionContreAvocatEmail,
        this.newMissionContreAvocatPhoneNumberResult.countryCode, this.newMissionContreAvocatPhoneNumberResult.nationalNumber,
      )
      .then((result) => {
        this.isMissionCreated = true
        this.createdMissionId = result.data.id
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addMissionInProcess = false
        this.addMissionButtonText = "Créer une nouvelle mission"
        this.addMissionButtonStyle = 'primary'
      })
    },

    // Prevention conflits
    searchClientActiveMissions() {
      if (this.$v.newMissionClientObject.$invalid) {
        return null
      }
      else {
        this.isSearchingClientActiveMissions = true
        apiUserConnected.getClientAllMissions(this.token, this.newMissionClientObject.id)
        .then((result) => {
          this.clientActiveMissions = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isSearchingClientActiveMissions = false
          this.duplicateClientActiveMissionsHasBeenSearched = true
        })
      }
    }
  }
}
</script>

<style>
.autocomplete__icon {
    height: unset;
    width: 14px;
}
</style>
